import React from 'react'
import classNames from 'classnames'
import styles from './manage_content.module.css'
import { AdminBox, QuickAccessButton, QuickAccessSharingButton, findApiV4Link } from 'shared'
import { ManageContentProps } from './types'

export const ManageContent = ({
  showManageContent,
  manageBlogsUrl,
  manageDetailsUrl,
  manageForwardingUrl,
  manageLiveWidgetsUrl,
  managePictureUrl,
  manageProjectsUrl,
  manageTransactionProtocolUrl,
  fundraisingEvent,
  category,
}: ManageContentProps): JSX.Element | null => {
  let shareUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'platform' }) ?? ''
  shareUrl += '?utm_campaign=creator_share&utm_medium=admin_box&utm_source=Link'
  const hasDonations = fundraisingEvent.donated_amount_in_cents > 0

  if (showManageContent === 'false') return null
  return (
    <div className="container">
      <div className="row pb-4">
        <div className="col mt-4">
          <AdminBox variant="dark" customTitle={i18n.t('fundraising_events.admin_boxes.header_title')} quickAccessBox>
            <div className="container">
              <div className={classNames(styles.buttonContainer, 'flex flex-wrap items-center justify-center')}>
                <QuickAccessButton type="edit_fundraising_event" url={manageDetailsUrl} />
                {hasDonations ? (
                  <QuickAccessButton type="write_news" url={manageBlogsUrl} />
                ) : (
                  <QuickAccessButton type="add_projects" url={manageProjectsUrl} />
                )}
                {category === 'live_streams' && (
                  <QuickAccessButton type="alerts_and_overlays" url={manageLiveWidgetsUrl} />
                )}
                {hasDonations ? (
                  <>
                    <QuickAccessButton type="forward_donations" url={manageForwardingUrl} />
                    <QuickAccessButton type="say_thank_you" url={manageTransactionProtocolUrl} />
                  </>
                ) : (
                  <QuickAccessButton type="edit_title_image" url={managePictureUrl} />
                )}
                {category !== 'live_streams' && !hasDonations && (
                  <QuickAccessButton type="write_news" url={manageBlogsUrl} />
                )}
                {!(category === 'live_streams' && hasDonations) && <QuickAccessSharingButton shareUrl={shareUrl} />}
                <QuickAccessButton type="all_settings" url={manageDetailsUrl} />
              </div>
            </div>
          </AdminBox>
        </div>
      </div>
    </div>
  )
}
